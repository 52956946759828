/**
 * 
 * @param {*} dateStr 时间字符串（时间戳等等）
 * @param {*} not_H_M_S 是否不显示 小时：分钟：秒数
 * @returns 
 */
const formatDate=(dateStr,not_H_M_S)=>{

  if (!dateStr) return

  const date=new Date(dateStr)
  let year = date.getFullYear()
  let month = date.getMonth() +1
  month= month<10?"0"+month:month
  let day = date.getDate()
  day= day<10?"0"+day:day
  
  // 时间
  let hour = date.getHours()
  hour= hour<10?"0"+hour:hour

  let min  = date.getMinutes()
  min= min<10?"0"+min:min

  let sec = date.getSeconds()
  sec= sec<10?"0"+sec:sec

  let res = year+"-"+month+"-"+day+" "+hour+":"+min+":"+sec

  if(not_H_M_S){
    res= year+"-"+month+"-"+day
  }



  return  res

}

export default formatDate