import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-03bfa000"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "message-i"
};
const _hoisted_2 = {
  class: "message-info-left"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "message-info-right"
};
const _hoisted_5 = {
  class: "nick-name"
};
const _hoisted_6 = {
  class: "date"
};
const _hoisted_7 = {
  class: "comment-con"
};
const _hoisted_8 = {
  class: "recover-wrapper"
};
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  key: 1
};
import { onMounted, ref, reactive } from 'vue';
import formatDate from '@/utils/formatDate';
export default {
  __name: 'Comment',
  props: {
    comment: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(__props, {
    emit
  }) {
    const props = __props;
    const comment = reactive(props.comment);
    const isMessage = ref(false);
    const commentCount = ref('');
    const showComment = ref(false);
    const handleShowComment = () => {
      showComment.value = !showComment.value;
      emit('showComment', {
        show: showComment.value
      });
    };
    const handelrecover = () => {
      emit('recover', {
        to: {
          id: comment.id,
          nickname: comment.nickname
        }
      });
    };
    onMounted(() => {
      if (props.type == 'comment') {
        isMessage.value = false;
      } else {
        isMessage.value = true;

        // 获取回复数量
        if (comment.commentList.length >= 100) {
          commentCount.value = '99+';
        } else {
          commentCount.value = comment.commentList.length;
        }
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        src: comment.headUrl,
        alt: ""
      }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createTextVNode(_toDisplayString(comment.nickname) + " ", 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(formatDate)(comment.createdAt)), 1)]), _createElementVNode("div", _hoisted_7, _toDisplayString(comment.content), 1)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
        class: "recover-btn",
        onClick: handelrecover
      }, "回复"), _withDirectives(_createElementVNode("div", {
        class: "show-comment-btn",
        onClick: handleShowComment
      }, [!showComment.value ? (_openBlock(), _createElementBlock("span", _hoisted_9, "展开")) : (_openBlock(), _createElementBlock("span", _hoisted_10, "收起")), _createTextVNode(" " + _toDisplayString(commentCount.value) + " 条 ", 1)], 512), [[_vShow, isMessage.value && commentCount.value >= 10]])])]);
    };
  }
};