import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "message-item"
};
const _hoisted_2 = {
  class: "message-info"
};
const _hoisted_3 = {
  class: "message-recover"
};
import Comment from '@/components/message/Comment';
import { ref } from 'vue';
export default {
  __name: 'MessageItem',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  setup(__props, {
    emit
  }) {
    const props = __props;
    const showComment = ref(false);
    const handleshowComment = e => {
      showComment.value = e.show;
    };
    const handlerecover = e => {
      emit('recover', {
        ...e,
        messageId: props.message.id
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(Comment), {
        comment: __props.message,
        type: "message",
        onShowComment: handleshowComment,
        onRecover: handlerecover
      }, null, 8, ["comment"])]), _withDirectives(_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.message.commentList, item => {
        return _openBlock(), _createBlock(_unref(Comment), {
          comment: item,
          type: "comment",
          onRecover: handlerecover,
          key: item.id
        }, null, 8, ["comment"]);
      }), 128))], 512), [[_vShow, __props.message.commentList.length < 10 || showComment.value]])]);
    };
  }
};