import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ArticleView from '@/views/ArticleView'
import EmptyView from '@/views/EmptyView'
import TypeArticleView from '@/views/TypeArticleView'
import MessageView from '@/views/MessageView'
import DataView from '@/views/DataView'
import FuncView from '@/views/FunctionView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/article',
    name: 'article',
    component: ArticleView
  },
  {
    path: '/empty',
    name: 'empty',
    component: EmptyView
  },
  {
    path: '/func',
    name: 'func',
    component: FuncView
  },
  {
    path: '/type',
    name: 'type',
    component: TypeArticleView
  },
  {
    path: '/message',
    name: 'message',
    component: MessageView
  },
  {
    path: '/data',
    name: 'data',
    component: DataView
  }
]

const router = createRouter({
  // history 复杂url，兼容性差，自定义404，需要配置服务器nginx
  history: createWebHistory(process.env.BASE_URL),
  // hash
  // history:createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
