
/**
 * 
 * @param {歌词显示时间格式为 00:00.00转为秒数} time 
 */
const formatWordsTime = (time)=>{
  let arr = time.split(":")
  return parseInt(arr[0])*60 + parseInt(arr[1])
}

export default  formatWordsTime