import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import MyNav from '@/components/Nav';
import MyFooter from '@/components/Footer';
import Background from '@/components/BackGround.vue';
import MyCanvas from '@/components/Canvas.vue';
import { onMounted } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import { jsonDataConfig } from '@/global/global';
export default {
  __name: 'App',
  setup(__props) {
    const axios = getCurrentInstance().proxy.$axios;
    const welcome = async () => {
      const {
        data: ip
      } = await axios.get('https://httpbin.org/ip');
      const {
        data: info
      } = await axios.get(`https://ipapi.co/${ip.origin}/json/`);
      const {
        data: res
      } = await axios.post('/blog/', {
        articleId: '',
        ip: ip.origin,
        info: info.country_name + ' ' + info.region + ' ' + info.city + ' ' + navigator.userAgent
      });
      if (res.status != 200) {
        return;
      }
      ElMessage.success(res.message);
    };
    onMounted(() => {
      welcome();
      // console.log(getCurrentInstance())
    });

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(Background), _createVNode(_unref(MyNav)), _createVNode(_component_router_view), _createVNode(_unref(MyFooter))], 64);
    };
  }
};