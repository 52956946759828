import "core-js/modules/es.array.push.js";
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["src"];
import router from '@/router';
import { reactive } from 'vue';
export default {
  __name: 'CardFunItem',
  props: {
    fun: {
      type: Object,
      default: {},
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const fun = reactive(props.fun);
    const gotoType = () => {
      router.push({
        path: '/type',
        query: {
          type: fun.name,
          image: fun.image
        }
      });
    };
    console.log(fun);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "fun-wrapper",
        onClick: gotoType
      }, [_withDirectives(_createElementVNode("img", {
        src: fun.image,
        alt: ""
      }, null, 8, _hoisted_1), [[_vShow, fun.image != '']]), _createElementVNode("span", null, [_createElementVNode("span", null, _toDisplayString(fun.name), 1)])]);
    };
  }
};