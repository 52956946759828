import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-519bbc9a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "data-wrapper max-width content-top"
};
const _hoisted_2 = {
  class: "data-item"
};
const _hoisted_3 = {
  class: "data-item"
};
const _hoisted_4 = {
  class: "data-item"
};
import * as echarts from 'echarts';
import { getCurrentInstance, onMounted, onUnmounted, ref } from 'vue';
import TopImage from '@/components/TopImage.vue';
import { ElMessage } from 'element-plus';
export default {
  __name: 'DataView',
  setup(__props) {
    const chartBarInstance = ref(null);
    const chartLineInstance = ref(null);
    const chartPieInstance = ref(null);
    const chartBar = ref(null);
    const chartLine = ref(null);
    const pie = ref(null);
    const axios = getCurrentInstance().proxy.$axios;
    onMounted(() => {
      initChart();
    });
    onUnmounted(() => {
      if (chartBarInstance.value) {
        chartBarInstance.value.dispose();
      }
    });
    const get7DaysArticleCount = async () => {
      const {
        data: res
      } = await axios.get('/blog/get7DaysArticleCountByCreateTime');
      if (res.status != 200) {
        ElMessage.error('按时间获取文章数量失败');
        return;
      }

      // this.articleOptions.xAxis.data = res.data.dateList
      // this.articleOptions.series[0].data = res.data.countList

      return res.data;
    };
    const getAllTagCount = async () => {
      const {
        data: res
      } = await axios.get('/blog/getAllTagCount');
      if (res.status != 200) {
        ElMessage.error('获取所有tag数量失败');
        return;
      }

      // this.tagOptions.series[0].data = res.data
      return res.data;
    };
    const get7DaysUserCount = async () => {
      const {
        data: res
      } = await axios.get('/blog/get7DaysUserCountByCreateTime');
      if (res.status != 200) {
        ElMessage.error('按时间获取访客数量失败');
        return;
      }
      return res.data;
      // this.customerOptions.xAxis.data = res.data.dateList
      // this.customerOptions.series[0].data = res.data.countList
    };

    function initChart() {
      initBar();
      initLine();
      initPie();
    }
    async function initBar() {
      chartBarInstance.value = echarts.init(chartBar.value);
      const data = await get7DaysArticleCount();

      // 在这里添加您的ECharts配置
      const option_bar = {
        title: {
          text: '每日文章量',
          top: '2%',
          left: '1%'
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          // 16个最多
          data: data.dateList
        },
        yAxis: {
          // type: 'value'
        },
        series: [{
          name: '文章数量',
          data: data.countList,
          type: 'bar'
        }]
      };
      chartBarInstance.value.setOption(option_bar);
    }
    async function initLine() {
      chartLineInstance.value = echarts.init(chartLine.value);
      const data = await get7DaysUserCount();
      const option_line = {
        title: {
          text: '每日进站浏览量',
          top: '2%',
          left: '1%'
        },
        xAxis: {
          type: 'category',
          data: data.dateList
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: data.countList,
          type: 'line',
          smooth: true
        }]
      };
      chartLineInstance.value.setOption(option_line);
    }
    async function initPie() {
      chartPieInstance.value = echarts.init(pie.value);
      const data = await getAllTagCount();
      const option_pie = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [{
          name: '标签',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: data
        }]
      };
      chartPieInstance.value.setOption(option_pie);
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(TopImage, {
        title: "网站数据"
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        ref_key: "chartBar",
        ref: chartBar,
        class: "data"
      }, null, 512)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        ref_key: "chartLine",
        ref: chartLine,
        class: "data"
      }, null, 512)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        ref_key: "pie",
        ref: pie,
        class: "data"
      }, null, 512)])])]);
    };
  }
};