import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wrapper"
};
import ArticleWrapper from '@/components/home/ArticleWrapper.vue';
import TopImage from '@/components/TopImage.vue';
export default {
  __name: 'HomeView',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(TopImage, {
        title: "欢迎来到~"
      }), _createVNode(ArticleWrapper, {
        class: "content-top"
      })]);
    };
  }
};