import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "card-wrapper"
};
import CardFunItem from './CardFunItem.vue';
import { ref } from 'vue';
export default {
  __name: 'CardFun',
  setup(__props) {
    const funItem = [{
      image: '',
      name: 'golang',
      path: ''
    }, {
      image: '',
      name: '小程序实战',
      path: ''
    }, {
      image: '',
      name: '大学生活',
      path: ''
    }, {
      image: '',
      name: '工作',
      path: ''
    }];
    const funItemList = ref(funItem);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(funItemList.value, item => {
        return _openBlock(), _createBlock(CardFunItem, {
          fun: item
        }, null, 8, ["fun"]);
      }), 256))]);
    };
  }
};